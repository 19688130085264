$danger: var(--bs-danger);

$dark: var(--bs-dark);
$gray: var(--bs-gray);
$gray-dark: var(--bs-gray-dark);
$light-gray: var(--bs-light-gray);

$light: var(--bs-light);
$white: var(--bs-white);

$primary: var(--bs-primary);
$success: var(--bs-success);

:root {
  --light-gray: #cccccc; // not part of bootstrap

  --light: #f3f3f3; // override bootstrap

  // for themes
  --theme-white: var(--bs-white);
  --theme-dark: var(--bs-gray-dark);
  --theme-light: var(--bs-light);
}

$theme-white: var(--theme-white);
$theme-dark: var(--theme-dark);
$theme-light: var(--theme-light);

.theme-dark-text {
  color: $theme-dark!important;
}

.theme-bg {
  background-color: $theme-white;
}

.theme {
  color: $theme-dark!important;
  background-color: $theme-white!important;
}

.theme-reverse {
  color: $theme-white!important;
  background-color: $theme-dark!important;
}

.btn-theme {
  @extend .theme;

  border-color: $theme-dark!important;
}

.btn-theme-reverse {
  @extend .theme-reverse;

  border-color: $theme-white!important;
}

// bg-light-st needs to move here and be renamed


@import '../node_modules/bootstrap/scss/bootstrap';

@import './themes/colors.scss';
@import './themes/modal.scss';

// Fixes for IOS
.html-for-ios {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

// Fixes for IOS
.body-for-ios {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}

* {
  touch-action: manipulation;
}

:root {
  --main-color: #007bff;
  --font-family-sans-serif: 'Quicksand';
}

html {
  font-size: 14px;

  @extend .html-for-ios;
}

body {
  @extend .body-for-ios;

  font-family: var(--font-family-sans-serif);

  background-color: $theme-white;
}

// Note - These are in bootstrap 4.2 https://twbs/bootstrap#27268
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}

.bg-light-st {
  background: $theme-light;
}

.w-inherit {
  width: inherit !important;
}

.lookup-input {
  padding-right: 2.25rem;
}

.lookup-icon {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
}

.errMsg {
  position: absolute;
  color: $danger;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
}

label {
  font-weight: 300;
}

.pointer {
  cursor: pointer;
}

xxx-article {
  position: fixed;
  top: 3rem;
  bottom: 3.3rem;
  left: 15rem;
  right: 0;
  overflow-y: auto;
  will-change: left;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.5) -0.0625rem 0 0.5rem;
}

.bg-st-theme-primary {
  background: $gray-dark !important;
}

.st-text-theme-primary {
  color: $primary; // Should not be important, need to be able to override;
}

.st-text-theme-primary-force {
  color: $primary !important; // ... but if you need important ...
}

.btn-primary {
  background: $primary !important;
}

.noselect {
  user-select: none;
}

.disabled {
  color: $light;
}

header {
  background: $primary;
}

footer {
  height: 3.3rem;
  overflow: hidden;
  left: 15rem;
  z-index: 2 !important;
  line-height: 3rem;
  z-index: 10;
  user-select: none;
}

.loader {
  margin-top: 15%;
  opacity: 0.6;
}

/* Icons in the small device view.  Opacity set to make them less prominent.
There will be better ways with color level 4 or some CSS guru-ry */
.report-action-icon {
  color: $gray-dark;
  opacity: 0.8;
}
.table-scroll {
  overflow: auto;
}

// Remove bottom left corner auth0 lock badge
// Hide from signalrconnectionid & code lock, whilst there is no out of the box hidden user metadata fields
.auth0-lock-badge-bottom {
  display: none !important;
}

// Remove icons from auth0 lock until we have icons for signup fields
.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
  padding-left: 0 !important;
}

// Use theme text in login button
.auth0-lock.auth0-lock .auth0-lock-submit {
  color: var(--primary-contrast) !important;
}

// Use theme colour in lock header
// .auth0-lock.auth0-lock .auth0-lock-header-bg {
//   background-color: var(--primary) !important;
// }

.fa-border {
  box-sizing: content-box;
}

.see-through {
  opacity: 0 !important;
}

.app-icon {
  max-width: 4rem;
}

.noHover {
  pointer-events: none;
}

// For login/ signup errors toastr to appear in front
.toast-container {
  z-index: 1000000 !important;
}

.fade-in {
  opacity: 0.6;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.65s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

.message-top-align {
  position: absolute !important;
  top: 2rem;
  width: 500px;
}

app-listreport .mat-checkbox-inner-container {
  margin-top: 0.2rem !important;
  height: 1.3rem !important;
  width: 1.3rem !important;
}

.jumbotron {
  @extend .p-5;
  @extend .bg-light;
  @extend .border;
  @extend .rounded-3;
}

// override the BS dark class, part of BS 5 migration
.bg-dark {
  background-color: $gray-dark !important;
}

.mat-dialog-container {
  @extend .p-0;
  background: transparent !important;
  box-shadow: none !important;
}

.mat-dialog-content {
  @extend .p-0;
  @extend .m-0;
}

.card-popup {
  @extend .card;

  .card-header {
    @extend .text-center;
    @extend .d-flex;

    :first-child {
      @extend .flex-grow-1;
    }
  }

  .modal-footer {
    @extend .p-2;
    @extend .border-top;
  }
}

.cardv2-popup {
  @extend .rounded-4;
  @extend .p-4;
  background-color: white;
  max-height: 100vh;
  overflow-y: auto;

  .cardv2-header {
    @extend .fw-bold;
    @extend .mb-4;
    text-align: start;
  }

  .cardv2-body {
    @extend .mb-4;
  }

  .cardv2-footer {
    @extend .d-flex;
    @extend .justify-content-end;
  }
}
pre {
  font-family: var(--font-family-sans-serif);
}

.no-readonly-underscore {
  border-bottom: hidden !important;
}

.sof-button {
  @extend .btn;
  @extend .rounded-4;
  @extend .px-1;
  min-width: 7rem;

  fa-icon {
    @extend .me-2;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

button.primary {
  @extend .sof-button;
  @extend .me-2;
  @extend .btn-primary;
}

button.primary-line {
  @extend .sof-button;
  @extend .me-2;
  @extend .btn-outline-primary;

  color: var(--bs-primary);
  border-color: var(--bs-primary);

  &:hover {
    color: var(--bs-white) !important;
    background-color: var(--bs-primary);
  }

    &:active,
    &:focus {
      color: var(--bs-primary) !important;
      background-color: white !important;
  }
}

button.secondary {
  @extend .sof-button;
  @extend .me-2;
  @extend .btn-outline-secondary;
}

// the close button would always be at the end.
// no need for me-2
button.close {
  @extend .sof-button;
  @extend .btn-outline-secondary;
  @extend .border-0;
}

// Override buttons to allow selective padding
// Needed as some localised text does not fit in the default button
// Apply this where the layout allows for a wider button with padding
button.padded {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.panel-container {
  // we need a fix width to stop the size changing while uploading
  // looks messy
  // override with media queries
  width: 35rem;

  @include media-breakpoint-only(xs) {
    width: auto;
  }
  @include media-breakpoint-only(sm) {
    width: auto;
  }
}

.drag-drop-area {
  @include media-breakpoint-only(xs) {
      display: none;
    }

    @include media-breakpoint-only(sm) {
      display: none;
    }
}

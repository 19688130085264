@use '@angular/material' as mat;

@include mat.core();
$primary: mat.define-palette(mat.$blue-palette, 800);
$accent:  mat.define-palette(mat.$light-blue-palette, 600, 100, 800);
$warn:    mat.define-palette(mat.$red-palette, 600);
$theme: mat.define-light-theme($primary, $accent, $warn);

$theme: map-merge(
  $map1: $theme,
  $map2: (foreground: map-merge(
    $map1: map-get($theme, foreground), 
    $map2: (disabled-text:rgba(black, 0.54))
    )
  )
);


@include mat.all-component-themes($theme);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: "Quicksand, sans-serif",
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500),
);

// Override the typography in the core CSS.
@include mat.core($custom-typography);

.mat-form-field-label{
  color: mat.get-color-from-palette(map-get($theme, foreground), secondary-text, 0.7);
}


/* Softools CSS prefix global internal css with sof- to stop any BS conflicts */
.sof-modal-container {
  display: grid;
  width: 100%;
}

.sof-modal {
  width: 50%;
  justify-self: center;
}

.sof-small-modal {
  @extend .sof-modal;
  max-width: 500px;
}

@media (max-width: 120rem) {
  .sof-modal {
    width: 80%;
  }
}

@media (max-width: 80rem) {
  .sof-modal {
    width: 90%;
  }
}

@media (max-width: 40rem) {
  .sof-modal {
    width: 100%;
  }
}

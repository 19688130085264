@import './themes/colors.scss';

$header-height: 3.1rem;
$footer-height: 3.2rem;
$padding-y: 0.3rem;

$aside-width: 20rem;

.wha-100 {
  position: absolute;
  height: 100%;
  width: 100%;
}


// CSS grid
.grid-container {
  @extend .wha-100;

  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  grid-template-areas:
    "title"
    "content";
}

.content-area {
  @extend .wha-100;

  grid-area: content;
}

.inner-grid-container {
  display: grid;

  grid-template-columns: minmax(min-content, max-content) 1fr auto;
  grid-template-rows: 1fr auto;

  height: 100%;
}

.report-grid-container {
  @extend .inner-grid-container;

  width: 100vw;
  height: 100%;
  //  height: calc(100vh - $header-height);

  grid-template-rows: auto auto 1fr $footer-height;

  grid-template-areas:
    "aside      ctrls    ctrls"
    "aside      header   filters"
    "aside      main     filters"
    "aside      footer   footer";

  background-color: #eeeeee;
}

.title-area {
  grid-area: title;
}

.quick-filters {
  grid-area: filters;
  background: transparent;
  height: 100%;
  overflow-y: hidden;
  padding-top: $padding-y;
  padding-bottom: $padding-y;
  max-width: 75vw;
}

.saved-filters {
  @extend .quick-filters;
  overflow: hidden;
}

.report-controls-area {
  grid-area: ctrls;
  background-color: $theme-light;
  height: 3.2rem;
}

.left-aside-area {
  grid-area: aside;

  aside {
    background: var(--bs-gray-dark);
    height: 100%;
    will-change: transform;
    overflow-y: auto;
  }
}

.main-content-area {
  grid-area: main;
  overflow-y: auto;
}

.header-area {
  grid-area: header;
}

.footer-area {
  grid-area: footer;
}

.report-content {
  padding: 1rem;
  padding-top: $padding-y;
  padding-bottom: $padding-y;
}

.report-article {
  border: 0;
  border-radius: 10px;
}
